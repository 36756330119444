@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.lb-event-edit {
    color: $blueSynchrone !important;
    display: flex;
    width: 100%;
    min-height: 340px;

    .react-datepicker__tab-loop {
        display: inline-block !important;
    }

    // .lb-event-calendar {
    //     width: 276px;
    //     min-height: 70px;
    //     //line-height: 70px;
    //     background-color: #d8d8d8;
    //     display: flex;
    // }

    .lb-event-edit-form {
        //min-height: 300px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .lb-event-form-header {
            text-align: left;
            line-height: initial;
            padding-left: 40px;
            padding-top: 25px;
            height: 80px;
            letter-spacing: 0.12px;
            font-size: 15px;
        }

        .lb-event-edit-delete-btn {
            height: 40px;
            line-height: 40px;
            cursor: pointer;
            padding-right: 25px;
            padding-top: 5px;
            width: 200px;
            position: relative;
            left: calc(100% - 200px);

            .delete-btn-label {
                display: inline-block;
                font-size: 12px;
                font-weight: 900;
            }
        }

        .lb-event-edit-header {
            background-color: $duckColor;
            font-size: 15px;
            color: $white;
            width: 100%;
            height: 70px;
            line-height: 70px;
            // display: inline-block;

            .lb-event-edit-header-title {
                display: inline-block;
                width: 30%;

                &.active {
                    font-weight: 600;

                    &:after {
                        content: "";
                        display: block;
                        width: 0;
                        height: 0;
                        margin-left: 160px;
                        margin-top: -1px;
                        border-left: 15px solid transparent;
                        border-right: 15px solid transparent;
                        border-top: 15px solid $duckColor;
                    }
                }
            }
        }

        .lb-event-form {
            > .mission-label {
                font-size: 14px;
                margin-bottom: 25px;
                line-height: initial;
                text-align: left;
                margin-left: 40px;
            }

            // .lb-event-form-type {
            //     font-size: 15px;
            //     font-weight: 800;
            //     text-transform: uppercase;
            //     display: inline;
            //     padding-left: 25px;

            //     &.activity-type {
            //         color: $menuColor;
            //     }

            //     &.absence-type {
            //         color: $vacationColor;
            //     }

            //     &.hours-not-worked-type {
            //         color: $hoursNotWorked;
            //     }
            // }
            .step1a-actions,
            .step1b-actions,
            .step1c-actions {
                display: flex;
                justify-content: space-evenly;

                > .category-button {
                    padding: 0 10px;
                    border: 2px solid $hoursNotWorked;
                    border-radius: 50px;
                    line-height: 45px;
                    width: 350px;
                    font-family: "Montserrat";
                    color: $blueSynchrone;
                    font-weight: 400;
                    font-size: 14px;
                    cursor: pointer;
                }
            }

            .step2-actions {
                display: flex;
                justify-content: space-evenly;
                height: 100px;
            }

            .step3-overtime {
                line-height: normal;

                // .step3-contents {
                //     display: contents;
                // }
            }

            .step3-standard {
                line-height: normal;
                height: 170px;

                // .step3-contents {
                //     display: contents;
                // }

                .moments {
                    display: flex;
                    width: 100%;
                    text-align: left;
                    height: 150px;
                    //margin: 40px 0;


                    .moment-start {
                        border-right: 2px solid #007288;
                        width: 100%;
                        height: 90px;

                        .custom-date-input {
                            border-bottom: 2px solid #9b9b9b;
                            text-transform: capitalize;
                            font-size: 15px;
                            font-weight: 700;
                            padding-bottom: 5px;
                            cursor: pointer;
                        }

                        .title {
                            line-height: 50px;
                        }

                        .date {
                            font-weight: 700;
                            display: inline-block;
                            text-transform: capitalize;
                            width: 150px;
                        }
                    }

                    .moment-end {
                        width: 100%;
                        padding-left: 50px;

                        .custom-date-input {
                            border-bottom: 2px solid #9b9b9b;
                            text-transform: capitalize;
                            font-size: 15px;
                            font-weight: 700;
                            padding-bottom: 5px;
                            cursor: pointer;
                        }

                        .title {
                            line-height: 50px;
                        }

                        .date {
                            font-weight: 700;
                            display: inline-block;
                            text-transform: capitalize;
                            width: 150px;
                        }
                    }
                }
            }

            .step4-actions {
                height: 150px;

                .days-count {
                    display: flex;
                    font-weight: 700;
                    position: relative;
                    top: -40px;
                    // height: 150px;

                    .days-count-header {
                        width: 100px;
                        text-align: left;
                    }

                    .days-count-total {
                        width: 100px;
                        text-align: left;
                    }
                }
            }

            .step3-actions {
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;

                .next-btn {
                    @include event-type-button(#27b19f);
                    border: none;
                    margin: 20px auto 0 auto;
                    position: absolute;
                    bottom: 0;
                    left: calc((100% - 235px) / 2);

                    > .label {
                        display: block;
                        text-align: center;
                    }
                }
            }

            .step4-actions {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 40px;
                position: relative;

                .next-btn {
                    @include event-type-button(#27b19f);
                    margin: 20px auto 0 auto;
                    position: absolute;
                    bottom: 0;
                    left: calc((100% - 235px) / 2);

                    > .label {
                        display: block;
                    }
                }
            }
        }

        #event_type {
            display: none;
        }

        .event-type-buttons {
            margin-top: 15px;
            margin-left: 0;
            display: flex;
            justify-content: space-around;

            .activity-type {
                @include event-type-button($menuColor);
            }

            .absence-type {
                @include event-type-button($vacationColor);
            }

            .hours-not-worked-type {
                @include event-type-button($hoursNotWorked);
            }
        }

        .event-code-buttons {
            margin: 0px 40px 0 40px;
            display: flex;
            justify-content: space-around;

            > .lb-event-action-btn {
                .label {
                    min-width: 195px;
                    width: calc(100% - 70px);
                    display: inline-block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            .event-shortname {
                color: $white;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                text-align: center;
                display: block;
                font-size: 13px;
                line-height: 40px;
                font-weight: 600;
                margin-top: 2px;
                margin-right: 2px;
                float: right;
            }

            .activity-type,
            .absence-type,
            .hours-not-worked-type {
                background-color: #f4f4f4;
                padding-left: 20px;
                text-align: left;
                color: #17313a;
                border-radius: 50px;
                line-height: 43px;
                height: 43px;
                min-width: 235px;
                cursor: pointer;
                font-family: "Montserrat";
                font-size: 14px;
                max-width: 400px;
                display: block;
            }

            .activity-type {
                .event-shortname {
                    background-color: $menuColor;
                }
            }

            .absence-type {
                .event-shortname {
                    background-color: $vacationColor;
                }
            }

            .hours-not-worked-type {
                .event-shortname {
                    background-color: $hoursNotWorked;
                }
            }

            .button-list {
                @include event-code-button();
                display: flex;
                flex-direction: column;

                .fal {
                    float: right;
                    margin-right: 10px;
                    margin-top: 15px;
                }

                > .list {
                    height: 300px;
                    margin-left: -20px;
                    max-width: 400px;
                    min-width: 290px;
                    overflow: auto;

                    > .lb-event-action-btn {
                        border-radius: 0;
                        height: 50px;
                        line-height: 50px;

                        &.activity-type {
                            &:hover {
                                background-color: $activityHovered;
                            }
                        }

                        &.absence-type {
                            &:hover {
                                background-color: $vacationHovered;
                            }
                        }

                        &.hours-not-worked-type {
                            &:hover {
                                background-color: $hoursNotWorkedHovered;
                            }
                        }

                        > .event-shortname {
                            margin-right: 10px;
                            margin-top: 5px;
                        }
                    }

                    > .lb-event-action-btn:last-child {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }

                &.open {
                    border-top-left-radius: 25px;
                    border-top-right-radius: 25px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        .lb-close {
            float: right;
            font-size: 42px;
            margin-right: 14px;
            margin-top: 14px;
        }
    }
}
