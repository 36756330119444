@import "modules/variables.scss";
@import "modules/mixins.scss";

body {
    font-family: $mainFont;
}

a {
    text-decoration: none;
}

#app {
    // padding-top: $headerHeight;
    min-width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

#tutorial-link {
    position: fixed;
    right: 0;
    top: 45%;
    background-color: $menuColor;
    height: 56px;
    width: 56px;
    border-radius: 50px 0 0 50px;
    padding-left: 12px;
    color: white;
    transition-duration: 250ms;
    line-height: 56px;
    overflow: hidden;
    white-space: nowrap;
    z-index: 10;
    font-size: 14px;
    font-weight: 600;

    &:hover {
        width: 200px;
    }

    i {
        font-size: 37px;
        position: relative;
        left: 0;
        top: 8px;
        margin-right: 15px;
    }
}

#dev-indicator {
    position: fixed;
    color: #fff;
    background-color: #eb5961;
    transform: rotate(-45deg);
    bottom: 25px;
    right: -75px;
    width: 250px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 28px;
    font-family: "Montserrat";
    z-index: 9999;
}

#preprod-indicator {
    position: fixed;
    color: $blueSynchrone;
    background-color: $adminHeaderColor;
    transform: rotate(-45deg);
    bottom: 35px;
    right: -60px;
    width: 250px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 28px;
    font-family: "Montserrat";
}

.container {
    width: 1200px;
    margin: 0 auto;
}

#app-container {
    width: 100%;
    height: calc(100% - #{$headerHeight});

    &.loging-page {
        height: 100%;
    }
}

#page-container {
    width: 100%;
    min-height: 100%;
    margin-top: $headerHeight;
    padding-top: 12px;
    // width: 1199px;
    // margin: 12px auto 0 auto;

    .view-only {
        * {
            cursor: initial !important;
        }

        .see-more-overtime {
            cursor: pointer !important;

            i {
                cursor: pointer !important;
            }
        }
    }

    .calendar-container {
        padding-bottom: 90px;

        #error-header {
            background-color: #f05a5a;
            color: #fff;
            height: 70px;
            line-height: 70px;
            width: 1200px;
            padding-left: 23px;
            font-weight: 600;

            i {
                float: left;
                margin-top: 13px;
                margin-right: 105px;
                font-size: 44px;
            }
        }

        .calendar {
            padding: 0 1px;
            display: flex;
            flex-wrap: wrap;

            .day {
                width: 169px;
                height: 120px;
                background-color: #fff;
                margin: 1px;
                cursor: pointer;
                font-family: "Montserrat";
                font-size: 18px;
                font-weight: 300;
                padding: 8px 10px;

                .hovered {
                    color: $white;
                }
            }

            .unavailable {
                background-color: #ebebeb;
                cursor: initial;
                color: #d0d0d0;
            }

            .not-current-month {
                background-color: #f2f2f2 !important;
            }

            .selected {
                background-color: rgba(0, 114, 136, 0.4);
            }

            .not-selectable {
                cursor: default;
            }
        }
    }
}

.warning-vacation-count {
    font-family: "Caveat", cursive;
    font-size: 24px;
    position: fixed;
    right: calc(((100% - 1200px) / 2) + 40px);
    bottom: 30px;
    width: 220px;

    p {
        transform: rotateZ(-15deg);
    }

    .warning-vacation-arrow {
        transform: rotateZ(100deg);
        height: 88px;
        position: relative;
        left: 140px;
        top: -30px;
    }
}

.warning-vacation-count-closed {
    transition: transform 500ms;
    transform: scale(0.2) translateY(680px);

    > .warning-vacation-arrow {
        // animation: rotateArrow 400ms;
        // top: 38px;
        // left: 200px;
        // transform: rotateZ(180deg);
    }
}

.purple-color {
    color: #9da6f2;
}

.red-color {
    color: #f05a5a !important;
    font-weight: 700 !important;
    font-size: 13px !important;
}

#recruit-popup-link {
    position: absolute;
    background-color: #efd8bd;
    border-radius: 10px 0 0 10px;
    width: 158px;
    height: 151px;
    right: 0;
    top: 140px;
    padding: 10px 0 14px 12px;
    cursor: pointer;
    z-index: 100;

    .recruit-popup-link-title {
        font-size: 14px;
        font-family: 'EB Garamond', serif;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 10px;
    }

    .recruit-popup-link-content {
        font-size: 12px;
        margin-bottom: 8px;
        font-family: 'proxima-nova';
    }

    .recruit-popup-link-about {
        font-size: 12px;
        font-family: 'proxima-nova';
        font-weight: 700;
        
        .learn-more-arrow {
            position: absolute;
            margin-top: -7px;
        }
    }
}

#scorp-popup-link {
    position: absolute;
    background-color: $corail;
    border-radius: 10px 0 0 10px;
    width: 50px;
    height: 60px;
    right: 0;
    top: 140px;
    padding: 10px 0 14px 12px;
    cursor: pointer;
    z-index: 100;
    color: #fff;

    .scorp-popup-link-title {
        font-size: 32px;
        margin-bottom: 10px;
    }
}

@keyframes moveArrow {
    0% {
        top: -30px;
    }

    100% {
        top: 30px;
        // left: 160px;
    }
}

@keyframes rotateArrow {
    0% {
        transform: rotateZ(100deg);
    }

    100% {
        transform: rotateZ(180deg);
    }
}

@keyframes hideWarning {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(90px);
    }
}
