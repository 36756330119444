@import "modules/variables.scss";
@import "modules/mixins.scss";

.vacation-totals {
    height: 136px;
    //width: 360px;
    padding: 15px 20px;

    > .vacation-totals-header {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: $blueSynchrone;
        font-family: "Open Sans";
        margin-bottom: 15px;
    }

    > .table {
        border-bottom: 2px solid $vacationColor;
        padding-bottom: 10px;
        min-height: 80px;

        > .table-header {
            margin-top: 5px;
            width: 328px;
            padding-left: 58px;
            float: right;
            border-bottom: 1px solid #4a4a4a;
            color: $blueSynchrone;

            > .table-header-cell {
                display: inline-block;
                width: 80px;
                text-align: right;
                font-family: "Open Sans";
                font-size: 12px;
                font-weight: 600;
            }
        }

        > .totals {
            font-size: 12px;
            font-weight: 700;
            font-family: $mainFont;

            > .totals-label {
                display: inline-block;
                color: $vacationColor;
                width: 75px;
            }

            > .n-total {
                display: inline-block;
                margin-right: 45px;
                font-weight: 600;

                > .n-total-label {
                    display: inline-block;
                    color: $blueSynchrone;
                    width: 25px;
                }

                > .n-total-amount {
                    display: inline-block;
                    color: $white;
                    font-size: 16px;
                    padding: 0 5px;
                    background-color: $vacationColor;
                }
            }

            > .rtt-total {
                display: inline-block;
                font-weight: 600;

                > .rtt-total-label {
                    display: inline-block;
                    color: $blueSynchrone;
                    width: 35px;
                }

                > .rtt-total-amount {
                    display: inline-block;
                    color: $white;
                    font-size: 16px;
                    padding: 0 5px;
                    background-color: $vacationColor;
                }
            }
        }

        > .remainder {
            font-size: 12px;
            font-weight: 700;
            font-family: $mainFont;
            display: inline-block;

            > .remainder-label {
                display: inline-block;
                width: 240px;
            }

            > .remainder-total {
                margin-left: 5px;
                display: inline-block;
                color: $vacationColor;
                font-weight: 700;
                width: 35px;
                text-align: right;
            }
        }

        > .before-june,
        > .rtt-remainder {
            // margin-top: 20px;
            display: inline-block;
            font-size: 12px;
            font-weight: 600;

            > .before-june-label,
            > .rtt-remainder-label {
                display: inline-block;
                color: $blueSynchrone;
                font-weight: 700;
                width: 240px;
            }

            > .before-june-amount,
            > .rtt-remainder-amount {
                margin-left: 5px;
                display: inline-block;
                color: $vacationColor;
                font-weight: 700;
                width: 35px;
                text-align: right;
            }
        }
    }
}
