@import "modules/variables.scss";
@import "modules/mixins.scss";

#overlay {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: rgba(10, 10, 10, 0.5);
    top: 0;
    left: 0;
    display: none;
    line-height: 150px;
    text-align: center;

    &.open {
        display: block;
    }
}
